// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme58,
  JSSThemeVariables58,
  getJSSThemePatterns58,
} from './JSSTheme58';
import { GeoblockPage58 } from './GeoblockPage58';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop58')).FullDesktop58,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile58')).FullMobile58,
  }),
  JSSTheme: JSSTheme58,
  JSSThemeVariables: JSSThemeVariables58,
  JSSThemePatterns: getJSSThemePatterns58,
  GeoblockPage: GeoblockPage58,
});
